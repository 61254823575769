<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin:  0;
  padding: 0;
}
.el-button--text{
  color:#007185!important
}
.el-dialog__header{
  padding: 10px 20px!important;
  text-align: left;
  background: #f5f5f5;
  .el-dialog__title{
    font-size: 16px!important;
  }
  .el-dialog__headerbtn{
    top: 15px!important;
  }
}
</style>
